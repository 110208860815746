/**
 *
 * InitiativeEditForm
 *
 */
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useQuery } from '@apollo/client';
import { GET_INITIATIVE_DETAILS } from '../../graphql/queries';

import { numberToGlobalId } from '../../helpers/helperFunctions';

import { Loading } from '../Loading';

import { Helmet } from 'react-helmet-async';

import GeneralDetails from './GeneralDetailsForm';
import InitiativeEditFormStyles from './styles';

import { Button, Col, Collapse, Form, Row } from 'antd';

import { EditInitiative } from '../../redux/actions/initiatives';

export const InitiativeEditForm = memo(props => {
  const [generalDetails, setGeneralDetails] = useState({
    english_name: '',
    arabic_name: '',
    english_description: '',
    arabic_description: '',
    starting_date: '',
    ending_date: '',
    starting_time: '',
    ending_time: '',
    location: '',
    tag: '',
    image: '',
    contract_en: '',
    contract_ar: '',
    registration_closed: false,
  });

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState(null);

  const { initiativeID } = useParams();

  const initiativeGID = numberToGlobalId('InitiativeNode', initiativeID);

  const { loading: initiativeLoading, data: initiativeData } = useQuery(
    GET_INITIATIVE_DETAILS,
    {
      variables: { id: initiativeGID },
    },
  );

  const onGeneralDetailsChange = event => {
    setGeneralDetails({
      ...generalDetails,
      [event.target.name]:
        event.target.name === 'image' ||
        event.target.name === 'contract_en' ||
        event.target.name === 'contract_ar'
          ? event.target.files[0]
          : event.target.value,
    });
  };

  const onFinish = async () => {
    const response = await dispatch(
      EditInitiative(generalDetails, initiativeID),
    );

    if (typeof response === 'string' && response.includes('Error'))
      setErrorMessage(response);
    else {
      setErrorMessage(null);
      window.location.href = `/initiatives/${initiativeID}/`;
    }
  };

  if (initiativeLoading) return <Loading />;

  const initiative = initiativeData?.initiative;

  const collapseItems = [
    {
      key: '1',
      label: 'General Details',
      children: (
        <GeneralDetails
          onChange={onGeneralDetailsChange}
          data={generalDetails}
          setData={setGeneralDetails}
          initiative={initiative}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>General Details Form</title>
        <meta name="description" content="General Details Form" />
      </Helmet>

      <InitiativeEditFormStyles>
        <Form onFinish={onFinish}>
          {errorMessage && <p className="text-error">{errorMessage}</p>}

          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Collapse defaultActiveKey={['1']} items={collapseItems} />
            </Col>

            <Col xs={24}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </InitiativeEditFormStyles>
    </>
  );
});
